var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.handleNext.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"heading"},[_vm._v(_vm._s(_vm.$t("register.user.heading")))]),_c('div',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("register.user.subheading"))+" ")])]),_c('v-img',{staticClass:"mx-auto cursor-pointer",attrs:{"src":require("@/assets/logos/milc-56.png"),"width":"60"},on:{"click":function($event){return _vm.$router.push({ name: 'home' })}}}),_c('div',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","rounded":"","label":_vm.$t('labels.firstName'),"rules":[_vm.rule.required]},model:{value:(_vm.value.first_name),callback:function ($$v) {_vm.$set(_vm.value, "first_name", $$v)},expression:"value.first_name"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","rounded":"","label":_vm.$t('labels.lastName'),"rules":[_vm.rule.required]},model:{value:(_vm.value.last_name),callback:function ($$v) {_vm.$set(_vm.value, "last_name", $$v)},expression:"value.last_name"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","rounded":"","label":_vm.$t('labels.orgName')},model:{value:(_vm.value.organization),callback:function ($$v) {_vm.$set(_vm.value, "organization", $$v)},expression:"value.organization"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","rounded":"","label":_vm.$t('labels.email'),"rules":[_vm.rule.required, _vm.rule.email],"type":"email","autocomplete":"username"},model:{value:(_vm.value.email),callback:function ($$v) {_vm.$set(_vm.value, "email", $$v)},expression:"value.email"}})],1)],1),_c('v-row',[_c('v-col',[_c('country-picker',{attrs:{"rounded":"true","outlined":"true","attrs":{
            label: _vm.$t('labels.country'),
            rules: [_vm.rule.required],
          }},model:{value:(_vm.value.country),callback:function ($$v) {_vm.$set(_vm.value, "country", $$v)},expression:"value.country"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","rounded":"","label":_vm.$t('labels.jobTitle'),"rules":[_vm.rule.required]},model:{value:(_vm.value.job_title),callback:function ($$v) {_vm.$set(_vm.value, "job_title", $$v)},expression:"value.job_title"}})],1)],1),_c('v-row',[_c('v-col',[_c('password-field',{attrs:{"outlined":"","rounded":"","attrs":{
            label: _vm.$t('labels.password'),
            rules: [_vm.rule.required, _vm.rule.password],
          }},model:{value:(_vm.value.password),callback:function ($$v) {_vm.$set(_vm.value, "password", $$v)},expression:"value.password"}})],1),_c('v-col',[_c('password-field',{attrs:{"outlined":"","rounded":"","attrs":{
            label: _vm.$t('labels.passwordConfirm'),
            rules: [
              _vm.rule.required,
              _vm.rule.same(_vm.$t('labels.password'), _vm.value.password) ],
          }},model:{value:(_vm.value.password_confirmation),callback:function ($$v) {_vm.$set(_vm.value, "password_confirmation", $$v)},expression:"value.password_confirmation"}})],1)],1)],1),_c('api-response',{staticClass:"mt-3",attrs:{"response":_vm.formResponse}}),_c('div',{staticClass:"text-center mt-5"},[_c('v-btn',{attrs:{"rounded":"","width":"380","disabled":!_vm.formValid,"loading":_vm.$store.getters['auth/loading'].emailExists,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("buttons.next"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }